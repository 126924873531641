import { Route, Routes } from "react-router-dom";
import Main from "./Pages/Main";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Main/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Routes>
    </div>
  );
}

export default App;
