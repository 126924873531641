import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Styles/variables.sass';
import './Styles/index.sass';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter> 
);
