import { Link } from 'react-router-dom';
import '../Styles/Pages/Main.sass';
import Header from '../Components/Header';

const Main = () => {

    const companyName = 'Hype Media Group';

    return (
        <>
            <Header/>
            <main id='promo' className='promo'>
                <div className="container main__wrapper">
                </div>
            </main>
            <section id='about' className='about'>
                <div className="container main__wrapper">
                    <h2 className="title">About Us</h2>
                    <p className="text">{companyName}, a rapidly expanding internet marketing firm, functions as a comprehensive advertising agency catering to its clientele. Our unceasing pursuit of perfection coupled with staying abreast of the latest marketing fads enables us to provide a diverse range of groundbreaking advertising remedies. We aid our associates in constructing their online marketing endeavors with utmost effectiveness.</p>
                    <p className="text">As a worldwide supplier of online video advertising solutions, {companyName} achieves impactful outcomes using its exclusive patented technology, which guarantees optimal value for advertising investments and heightened viewer interaction.</p>
                    <p className="text">We are a forward-thinking advertising firm with expertise in programmatic video, trading desk services, and the creation of solutions for Web and mobile platforms (primarily iOS and Android), as well as software for advertising service management. Our goal is to pave the way for a brighter future by offering cutting-edge technologies to businesses. We aim to offer effective guidance, reshape outdated corporate norms, consistently deliver higher-quality software, and produce exceptional products.</p>
                    <div className="box__wrapper">
                        <div className="box">
                            <h4 className="subtitle">Experience combined with versatility</h4>
                            <p className="text">We possess profound expertise in traffic administration, analytics, monetization, and product endorsement across web, mobile, and social channels. Leveraging our extensive background in development and marketing, we have the capacity to devise strategies that assure recognition and triumph for every project we undertake. Our unwavering commitment to innovation leads us to continuously generate fresh concepts, resulting in lucrative projects for our partners and a competitive edge in the market. The professionals at {companyName} initiate and oversee campaigns, as well as design viable web and mobile products tailored to numerous industries across the globe.</p>
                        </div>
                        <div className="box">
                            <h4 className="subtitle">Our Mission</h4>
                            <p className="text">The core objective of {companyName} is to aid our collaborators in the growth and promotion of their ventures, effectively fulfilling their traffic requirements, and bestowing them with a competitive advantage. Our focus remains on nurturing strong and reliable partnerships with our clients. Through a blend of creativity, innovation, and steadfastness, our comprehensive performance-oriented real-time bidding (RTB) advertising platform is adept at mitigating risks and attaining substantial returns on investment.</p>
                        </div>
                    </div>
                </div>          
            </section>
            <section id='solutions' className='solutions'>
                <div className="container main__wrapper">
                    <div className="wrapper">
                        <h2 className="title">Solutions</h2>
                        <div className="box__wrapper">
                            <div className="box">
                                <h4 className="subtitle">High-Impact Interactive Ads</h4>
                                <p className="text">By offering assistance for a wide array of ad formats currently existing in the market, we empower you to vastly expand your reach within your intended audience. Through our unified platform, we provide interactive and personalized creatives that play a crucial role in elevating your creative conversion rate and achieving a heightened click-through rate.</p>
                            </div>
                            <div className="box">
                                <h4 className="subtitle">Performance Optimization</h4>
                                <p className="text">In the rapidly evolving landscape of online advertising, risk mitigation stands as the paramount concern for all companies. Through our models based on Cost Per Lead (CPL) or Cost Per Sale (CPS), you pay solely for traffic that holds relevance, and we will connect you with esteemed publishers. This partnership ensures optimal efficiency for your marketing campaign, allowing you to attain the highest level of effectiveness.</p>
                            </div>
                            <div className="box">
                                <h4 className="subtitle">Retargeting</h4>
                                <p className="text">Comprising Google AdWords certified professionals with more than 5 years of expertise in remarketing, we constitute a team that has orchestrated numerous triumphant remarketing campaigns. Our endeavors enable you to re-engage with your website's visitors and directly target potential clients who have shown interest in your offerings.</p>
                            </div>
                            <div className="box">
                                <h4 className="subtitle">Global Reach</h4>
                                <p className="text">Leveraging our access to data from the foremost display, video, social, and mobile marketplaces, we possess the capability to engage in accurate media buying across an extensive range of traffic sources, employing various available models. Our team includes top-tier affiliates, ensuring a global presence and utmost efficiency in performance.</p>
                            </div>
                            <div className="box">
                                <h4 className="subtitle">Big Data Management</h4>
                                <p className="text">Within our platform, we facilitate a comprehensive selection of mobile ad formats, encompassing plain text, text paired with images, and rich media. By tailoring your creative content to accommodate portable devices, we facilitate a substantial enhancement in the pertinence of targeted advertising for mobile users. This precision in delivering appropriate advertising content to the appropriate audience at the optimal moment plays a pivotal role in maximizing your revenue potential.</p>
                            </div>
                            <div className="box">
                                <h4 className="subtitle">Brand safe environment</h4>
                                <p className="text">Our primary aim is to optimize your revenue by introducing fresh concepts into your advertising campaigns and product advancement. We seamlessly merge all media channels to scrutinize and enhance the audience's interaction with your brand. Furthermore, a suite of intelligent brand safety tools ensures the safeguarding of your brand's integrity across the online landscape.</p>
                            </div>
                        </div>
                    </div>
                    <div id='verticals' className="verticals">
                        <h2 className="title">Verticals</h2>
                        <p className="text">Operating across a range of verticals and specialized niches, {companyName} is notably active in areas such as Travel, E-commerce, Social Networking, Social and Mobile Apps, Health, Dating, Gaming, and Financial domains. Our selection of verticals is carefully aligned with market trends and tailored to address the requirements and preferences of our partners. Presently, we provide CPA promotion for an expanding array of web, social, and mobile offers. Additionally, our scope extends to development solutions spanning over 10 distinct verticals.</p>
                    </div> 
                </div>
            </section>
            <section id='advetisers' className='advetisers'>
                <div className="container main__wrapper">
                    <h2 className="title">Advertisers</h2>
                    <p className="text">At {companyName}, we recognize that achieving effective brand promotion, fostering meaningful user engagement, and attaining your goals necessitate a versatile media approach. Our amalgamation of seasoned experts, cutting-edge technology, high-quality media outlets, and esteemed affiliates ensures an unparalleled level of responsiveness.</p>
                    <h4 className="subtitle">These are several reasons why being our partner leads to success:</h4>
                    <div className="box__wrapper">
                        <ul className="box">
                            <li className="text">Our analytics experts will reduce your risks while maximizing your return on investment (ROI).</li>
                            <li className="text">Our team holds Google certification and boasts over 5 years of expertise in retargeting.</li>
                            <li className="text">A network of reliable affiliates and our in-house media buying teams guarantee the quality of traffic.</li>
                        </ul>
                        <ul className="box">
                            <li className="text">We operate across all platforms (web, mobile, and social) and function under various models, including CPM, CPC, CPA, and revenue share.</li>
                            <li className="text">Our unified self-service platform, seamlessly integrated with a high-performance DSP, offers transparent control over all your online advertising campaigns.</li>
                            <li className="text">Through innovation and contemporary scientific methods, our highly qualified Research and Development team will elevate your online advertising to an entirely new level.</li>
                        </ul>
                    </div>
                    <p className="text">We don't simply purchase media; we engage in a collaborative partnership with our clients, starting from the inception of the campaign concept and extending to its implementation and performance evaluation. {companyName} is a comprehensive performance-oriented marketing agency that caters to all your requirements and surpasses them significantly.</p>
                </div>
            </section>
            <footer id='contacts' className='contacts'>
                <div className="container main__wrapper">
                    {/*<h2 className="title">Contacts</h2>*/}
                    {/*<div className="box__wrapper">*/}
                    {/*    <form className="contacts__form">*/}
                    {/*        <h3 className='subtitle'>Contact Form:</h3>*/}
                    {/*        <div className="contacts__input">*/}
                    {/*            <label htmlFor="name">Your name</label>*/}
                    {/*            <input id='name' type="text" />*/}
                    {/*        </div>*/}
                    {/*        <div className="contacts__input">*/}
                    {/*            <label htmlFor="email">Your email *</label>*/}
                    {/*            <input id='email' type="email" />*/}
                    {/*        </div>*/}
                    {/*        <div className="contacts__input">*/}
                    {/*            <label htmlFor="subject">Subject *</label>*/}
                    {/*            <input id='subject' type="text" />*/}
                    {/*        </div>*/}
                    {/*        <div className="contacts__input">*/}
                    {/*            <label htmlFor="message">Your message (optional)</label>*/}
                    {/*            <textarea id="message"></textarea>*/}
                    {/*        </div>*/}
                    {/*        <div className="contacts__btn__wrapper">*/}
                    {/*            <button type='submit' className="contacts__btn">Submit</button>*/}
                    {/*        </div>*/}
                    {/*    </form>*/}
                    {/*    <div className="contacts__address">*/}
                    {/*        <h3 className='subtitle'>Our address:</h3>*/}
                    {/*        <p className="text">United Kingdom, London, W1W 7LT</p>*/}
                    {/*        <p className="text">85 Great Portland Street, First Floor</p>*/}
                    {/*        <iframe title='map' src="https://maps.google.com/maps?q=85%20Great%20Portland%20Street%2C%20First%20Floor%2C%20London%2C%20W1W%207LT%2C%20United%20Kingdom&t=m&z=10&output=embed&iwloc=near" loading="lazy"></iframe>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="extra">
                        <div className="extra__text">
                            For advertising inquiries please contact us via email:
                        </div>
                        <a href='mailto:partners@hypegroupmedia.com' className="extra__email">
                            partners@hypegroupmedia.com
                        </a>
                    </div>
                    <div className="contacts__footer">
                        <div>Copyright (с) - {companyName}</div>
                        <Link to='/privacy-policy'>Privacy Policy</Link>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Main;