import '../Styles/Components/Header.sass';
import logo from '../Images/logo_white.svg';
import logoMobile from '../Images/logo_small_white.svg';

const Header = () => {

    const toggleHeader = () => {
        document.querySelector('.header__list_mobile')?.classList.toggle('header__list_mobile_active');
        document.querySelector('.header__hamburger')?.classList.toggle('header__hamburger_active');
    }

    return (
        <header className='header'>
            <a href='#promo' className="header__wrapper">
                <img className="header__logo" src={logo} alt="Logo"/>
                <img className="header__logo_mobile" src={logoMobile} alt="Logo"/>
            </a>
            <nav className='header__nav container'>
                <ul className='header__list header__list_mobile'>
                    <li><a onClick={toggleHeader} href="#about">About Us</a></li>
                    <li><a onClick={toggleHeader} href="#solutions">Solutions</a></li>
                    <li><a onClick={toggleHeader} href="#verticals">Verticals</a></li>
                    <li><a onClick={toggleHeader} href="#advetisers">Advertisers</a></li>
                    <li><a onClick={toggleHeader} href="#contacts">Contacts</a></li>
                </ul>
                <div onClick={toggleHeader} className="header__hamburger">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </nav>
        </header>
    );
};

export default Header;